<template>
	<div :id="'scroll-document' + idScroll" class="scrolling-document">
		<ScrollingPage
			v-for="page in pages"
			:key="page.pageNumber"
			v-slot:default="{ isPageFocused, isElementFocused }"
			v-bind="{ page, clientHeight, scrollTop, focusedPage, enablePageJump }"
			@page-jump="onPageJump"
		>
			<div class="scrolling-page">
				<slot v-bind="{ page, isPageFocused, isElementFocused }"></slot>
			</div>
		</ScrollingPage>

		<div v-visible="fetchPages" class="observer"></div>
	</div>
</template>

<script>
import PDFPreview from '@/helpers/PDFPreview'

export default {
	components: {
		ScrollingPage: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer/ScrollingPage')
		})
	},
	directives: {
		visible: PDFPreview.directives.visible,
		scroll: PDFPreview.directives.scroll
	},
	props: {
		pages: {
			required: true,
			type: Array
		},
		enablePageJump: {
			type: Boolean,
			default: true
		},
		currentPage: {
			type: Number,
			default: 1
		},
		isParentVisible: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			focusedPage: this.currentPage,
			scrollTop: 0,
			clientHeight: 0,
			idScroll: Math.random() * 1000,
			scrollingElement: null
		}
	},
	computed: {
		pagesLength () {
			return this.pages.length
		}
	},
	watch: {
		isParentVisible: 'updateScrollBounds',
		pagesLength (count, oldCount) {
			if (oldCount === 0) {
				this.$emit('pages-reset')
			}
			// Set focusedPage after new pages are mounted
			this.$nextTick(() => {
				this.focusedPage = this.currentPage
			})
		},
		currentPage (currentPage) {
			if (currentPage > this.pages.length) {
				this.fetchPages(currentPage)
			} else {
				this.focusedPage = this.currentPage
			}
		}
	},
	mounted () {
		this.scrollingElement = document.getElementById('scroll-document' + this.idScroll) 
		this.scrollingElement.addEventListener('scroll', () => this.handleScroll(), true)
	},

	destroyed () {
		this.scrollingElement.removeEventListener("scroll", () => this.handleScroll(), true);  
	},
	methods: {
		fetchPages (currentPage) {
			this.$emit('pages-fetch', currentPage)
		},

		onPageJump (scrollTop) {
			this.$emit('page-jump', scrollTop)
		},

		handleScroll () {
			let waiting = false
			if (!waiting) {
				this.updateScrollBounds()
				waiting = true
				setTimeout(function () {
				waiting = false
				}, 300)
			}
		},

		updateScrollBounds () {
			const { scrollTop, clientHeight } = this.$el
			this.scrollTop = scrollTop
			this.clientHeight = clientHeight
		}
	}
}
</script>

<style>
.observer {
	height: 1px;
}
</style>
